import React from "react";
import styles from "./styles.module.css";
import Button from "../../../components/Button";
import promotion from "../../../../../assets/img/promotion.gif";

export default function PromotionDeSiteWeb() {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <h1>Promotion de site Web</h1>
        <img
          src={promotion}
          alt="Graphique de tendance à la hausse"
          style={{ float: "right" }}
        ></img>
        <p>
          NEBS offre des services professionnels de courriel, d'hébergement et
          de conception graphique Web qui vous permettront de maximiser les
          efforts que vous déployez à promouvoir votre site. Enregistrez votre
          domaine chez nous dès aujourd'hui et constatez tous les avantages du
          service d'hébergement que nous offrons et qui fait de NEBS le meilleur
          hébergeur pour votre site Web.
        </p>
      </div>
      <div className={styles.row}>
        <div className={styles.column}>
          <div className={styles.card}>
            <h2>Communiquez avec vos clients</h2>
            <h3>Envoyez des infolettres avec Announcer Pro</h3>
            <ul>
              <li>
                Renforcez la fidélité de votre clientèle par des communications
                régulières
              </li>
              <li>
                Informez vos clients de ce qu'il y a de nouveau dans votre
                entreprise
              </li>
              <li>Faites la promotion de nouveaux produits ou services</li>
            </ul>
            <div className={styles.button_wrapper}>
              <Button
                text={"D'INFO"}
                url={
                  "/developpez/services-web/promotion-de-site-web/announcer-pro/"
                }
                margin={"10px 0"}
                minWidth={"200px"}
              />
            </div>
          </div>
        </div>
        <div className={styles.column}>
          <div className={styles.card}>
            <h2>Faites-vous repérer facilement sur internet</h2>
            <h3>
              Améliorez votre classement dans les résultats de recherche avec
              EasySiteOptimizer
            </h3>
            <ul>
              <li>
                Améliorez votre positionnement habituel dans les résultats de
                recherche
              </li>
              <li>
                Déterminez les mots clés qui seront associés à votre site Web
              </li>
              <li>
                Faites la promotion de votre site auprès des principaux moteurs
                de recherche de l'Internet : Google & Yahoo!
              </li>
            </ul>
            <div className={styles.button_wrapper}>
              <Button
                text={"D'INFO"}
                margin={"10px 0"}
                minWidth={"200px"}
                url={
                  "/developpez/services-web/promotion-de-site-web/easysiteoptimizer/"
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
