import React from "react";
import Layout from "../../../../other/layout";
import PromotionDeSiteWeb from "../../../../components/AC-StaticPages/Developpez/ServicesWeb/PromotionDeSiteWeb/PromotionDeSiteWeb";
import SEO from "../../../../other/seo";

export default function promotionDeSiteWeb() {
  return (
    <Layout>
      <SEO title="Promotion de site Web" />
      <PromotionDeSiteWeb />
    </Layout>
  );
}
